import styled from 'styled-components';
import { SectionHeader } from '@naf/section-header';
import { AdvancedImage } from '@cloudinary/react';
import { GridCol, GridRow } from '@naf/grid';
import { CardGrid } from '@naf/card-grid';
import { nafColor } from '@nafcore/theme';
import { breakpoints, spacing } from '@naf/theme';
import { MainContent } from '../../components/layout/Layout';

export const SectionContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  max-width: ${breakpoints.xl};
  margin: auto;
  padding: ${spacing.space48} ${spacing.space48} ${spacing.space64} ${spacing.space48};
  flex-direction: column;
  row-gap: ${spacing.space40};

  .threeColumnBlock {
    margin-bottom: ${spacing.space32};
  }

  .TextAndImageBlock {
    padding-bottom: ${spacing.space32};

    @media (max-width: ${breakpoints.m}) {
      padding-bottom: 0;
    }

    img {
      border-radius: ${spacing.space8};
    }

    a {
      margin-right: ${spacing.space8};
      margin-bottom: ${spacing.space8};
    }
  }

  .TextBlock {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: ${spacing.space24};
    padding: ${spacing.space32} 0;

    .TextBlockItem {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      grid-column: auto / span 6;
      grid-column-start: 4;

      @media (max-width: ${breakpoints.s}) {
        grid-column-start: 1;
        grid-column: auto / span 12;
      }
    }
  }

  @media (max-width: ${breakpoints.l}) {
    padding: ${spacing.space24};
    row-gap: ${spacing.space24};
  }
`;

export const StyledAdvancedImage = styled(AdvancedImage)`
  @media (max-width: ${breakpoints.s}) {
    display: none;
  }
`;

export const StyledMainContent = styled(MainContent)`
  margin-bottom: ${spacing.space120};
  margin-top: ${spacing.space48};
`;

export const StyledSectionHeader = styled(SectionHeader)`
  h1 {
    padding-top: ${spacing.space160};

    @media (max-width: ${breakpoints.l}) {
      padding-top: ${spacing.space120};
    }
    @media (max-width: ${breakpoints.m}) {
      padding-top: 95px;
    }
  }
`;

export const HeaderGridCol = styled(GridCol)`
  background: ${({ theme }) => (theme.background ? theme.background.default : 'inherit')};
  margin-top: 44px;
  padding-left: ${spacing.space48};
  padding-right: ${spacing.space48};

  @media (max-width: ${breakpoints.l}) {
    margin-top: 52px;
  }

  @media (max-width: ${breakpoints.m}) {
    margin-left: -12px;
    padding-left: 12px;
    margin-top: 44px;
  }

  p {
    margin: 0;
  }

  h1 {
    margin: ${spacing.space48} 0 ${spacing.space20};

    @media (max-width: ${breakpoints.m}) {
      margin: ${spacing.space20} 0 0;
    }
  }
`;

export const MainRow = styled(GridRow)`
  padding-left: ${spacing.space48};
  margin-bottom: ${spacing.space40};

  @media (max-width: ${breakpoints.m}) {
    padding-left: 0;
    margin-bottom: 0;
    grid-row-gap: ${spacing.space20} !important;
  }
`;

export const SecondaryRow = styled(GridRow)`
  margin-bottom: ${spacing.space40};

  @media (max-width: ${breakpoints.m}) {
    padding-left: 0;
    margin-bottom: 0;
    grid-row-gap: ${spacing.space20} !important;
  }
`;

export const SectionCol = styled(GridCol)`
  padding-left: ${spacing.space48};
  padding-top: ${spacing.space24};
  padding-bottom: ${spacing.space24};

  @media (max-width: ${breakpoints.m}) {
    padding-left: 0;
    padding-top: ${spacing.space12};
    padding-bottom: ${spacing.space12};
  }

  h2 {
    margin: 0;
    margin-bottom: ${spacing.space24};
  }
`;

export const ListSectionRow = styled(SecondaryRow)`
  @media (max-width: ${breakpoints.s}) {
    grid-row-gap: 0 !important;
  }
`;

export const StyledCardGrid = styled(CardGrid)`
  overflow: hidden;

  h2 {
    margin: ${spacing.space16} 0;
  }

  @media (min-width: ${breakpoints.m}) {
    font-size: 1.25rem;
    line-height: ${spacing.space32};

    h2 {
      margin: ${spacing.space24} 0 ${spacing.space12};
    }
  }

  @media (max-width: ${breakpoints.s}) {
    margin-bottom: 0;
  }
`;

export const HeaderColorBox = styled.div`
  height: 257px;
  width: 100vw;
  position: absolute;
  left: 0;
  top: calc(-129px + 120px);
  z-index: -100;
  background: ${nafColor.primary.dew};

  @media (max-width: ${breakpoints.m}) {
    top: calc(-129px + 80px);
    height: 228px;
  }

  @media (max-width: ${breakpoints.s}) {
    height: 215px;
  }
`;

export const ReadMoreWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.space40};
`;

export const CardGridRow = styled(GridRow)`
  @media (max-width: ${breakpoints.m}) {
    grid-row-gap: ${spacing.space48} !important;
  }
`;
